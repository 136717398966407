import { sample, sortBy, uniq } from 'lodash';
import DataResource from '@core/resources/DataResource';

export default class Well extends DataResource {
  constructor (data = {}) {
    super(data);
    this.meta = { suggestions: [], ...this.meta, ...data.meta };
  }

  setOptions (options = {}) {
    this.options = {
      page: options.page || 1,
      pageCount: options.pages || 0,
      total: options.total || options.count || 0,
      itemsPerPage: options.page_size || options.itemsPerPage || 10,
      rowsPerPage: uniq(sortBy([ options.page_size || 10, 5, 10, 15, 20, 100 ])),
      hideFooter: this.items.length < (options.itemsPerPage || 10),
      ...options,
    };
  }

  setQueryString (query = {}) {
    const queryString = { ...query, ...this.options };

    this.query = {
      page: queryString.page,
      sort: queryString.sort || queryString.sortBy,
      order: queryString.order || queryString.sortDesc,
      ...query,
    };
  }

  getRandomSuggestion () {
    return sample(this.meta.suggestions);
  }
}
