<template>
  <div>
    <template v-if="hasData">
      <v-simple-table class="v-data-table--denser">
        <tbody>
          <tr v-for="(row, i) in rows" :key="i">
            <td
              class="caption text-no-wrap font-weight-bold text-uppercase"
              v-text="$trans(row.text)"
            ></td>
            <td class="caption" v-text="row.value"></td>
          </tr>
        </tbody>
      </v-simple-table>
    </template>
    <template v-else>
      <general-summary-info-table-empty-state></general-summary-info-table-empty-state>
    </template>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';

export default {
  name: 'GeneralSummaryInfoTable',

  props: {
    item: {
      type: [ Object, Array ],
      default: () => {},
    },
  },

  data: vm => ({
    well: vm.item.data,
  }),

  computed: {
    hasData () {
      return !isEmpty(this.well);
    },

    rows () {
      return [
        {
          text: 'Project name',
          value: this.well.project_name,
        },
        {
          text: 'Company',
          value: this.well.company,
        },
        {
          text: 'Field',
          value: this.well.field,
        },
        {
          text: 'Start',
          value: this.well['start date'],
        },
        {
          text: 'End',
          value: this.well['end date'],
        },
        {
          text: 'Platform',
          value: this.well.platform,
        },
      ];
    },
  },
};
</script>
