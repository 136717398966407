import { BASE_URL } from '@/config/api';

export const AUTH_LOGIN_URL = `${BASE_URL}/api-token-auth/`;
export const REFRESH_TOKEN_URL = `${BASE_URL}/api-token-refresh/`;
export const SEND_RESET_PASSWORD_URL = `${BASE_URL}/users/generate-reset-code/`;

export default Object.freeze({
  AUTH_LOGIN_URL,
  REFRESH_TOKEN_URL,
  SEND_RESET_PASSWORD_URL,
});
