import { BASE_URL } from '@/config/api';

export const GET_WELLS_INFO_URL = `${BASE_URL}/wells/info/`;
export const FIND_WELL_INFO_URL = id => `${BASE_URL}/wells/info/${id}/`;
export const EXPORT_WELLS_URL = `${BASE_URL}/wells/info/export-to-csv/`;

export default Object.freeze({
  GET_WELLS_INFO_URL,
  EXPORT_WELLS_URL,
  FIND_WELL_INFO_URL,
});
